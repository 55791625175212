// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-office-js": () => import("./../../../src/pages/openOffice.js" /* webpackChunkName: "component---src-pages-open-office-js" */),
  "component---src-pages-presentation-js": () => import("./../../../src/pages/presentation.js" /* webpackChunkName: "component---src-pages-presentation-js" */),
  "component---src-pages-visit-js": () => import("./../../../src/pages/visit.js" /* webpackChunkName: "component---src-pages-visit-js" */)
}

